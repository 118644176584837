import React, { HTMLProps, useEffect, useMemo, useState } from 'react';
import { cva } from 'class-variance-authority';

import {
   filterServiceProfiles,
   resolveCategoryName,
   filterByName,
   getAllProviders
} from '../helper';

import { CONTAINER_TITLE, CONTAINER_DESCRIPTION } from './constant';
import Section from './section';
import categories from '../../../pages/integrations/category.json';
import { cn } from '../../../utils/common';
import { SectionContainer } from '../../common/sectionContainer';

export type ItemTypes = {
   name: string
   type: string,
   image: {
      original: string
   }
} & { [key: string]: any }

type Props = {
   selectedCategory?: string
   sectionsRef: any

} & HTMLProps<HTMLDivElement>;


const rootVariant = cva(
   cn('flex flex-col gap-5'),
   {
      variants: {

      }
   }
);

const SELECTED_CATEGORY = 'all-integrations';
const items = getAllProviders() ?? []

export default (props: Props) => {

   const {
      selectedCategory,
      className,
      sectionsRef,
      ...rest
   } = props;

   const [query, setQuery] = useState<string>('');

   const [queried, setQueried] = useState<typeof items>([])

   const category: Record<string, any> = useMemo(() => resolveCategoryName(selectedCategory) as any, [selectedCategory])

   // search
   const onSearch: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
      const { value: newQuery } = target;
      setQuery(newQuery)
   }

   useEffect(() => {

      // Step 1: Initialize the 'updated' variable with the 'items' array
      let updated = items;

      if (query) {
         // Step 4: If 'selectedCategory' is the same as 'SELECTED_CATEGORY' and there's a query, filter by name using 'filterByName'
         updated = filterByName(query);
      }
      updated = updated.sort((a, b) => a.name.localeCompare(b.name));

      // Step 6: Update the state with the sorted and filtered list of items
      // setQueried(updated);

      // Step 5: Update the state with the filtered list of items
      setQueried(updated);

   }, [selectedCategory, query]); // Step 6: Effect depends on 'selectedCategory' and 'query'

   return (
      <div
         {...rest}
         className={rootVariant({ className })}
      >
         {/* {category?.name ? (
            <div className='flex flex-col '>
               <span className='text-xl font-semibold'>{category?.name}</span>
               <span className='text-gray-700 font-NORMAL text-sm mt-2'>{category?.description}</span>
            </div>
         ) : (
            <FallbackContainer />
         )} */}
         {/* <SectionContainer
               title={<span>Connect to your Customer's integrations with <span className='primary-text-gradient'>one API  </span></span>}
               description={`Pull data and take actions across the security stack with Unizo Connectors.`}
            /> */}

         <input
            type="text"
            className="bg-gray-50 border-[1px]  border-solid border-gray-200 text-gray-900 text-sm rounded-lg  w-full p-2.5 "
            placeholder="Search"
            onChange={onSearch}
            value={query}
         />
         

         {categories.map((item, index) => {
            const { name, value } = item
            const filtered = filterServiceProfiles(value, query)
            if (filtered.length === 0) {
               return null;
            }
            return (
               <Section
                  sectionsRef={sectionsRef}
                  items={filtered}
                  title={name}
                  key={index}
                  index={index}
                  nodeId={value}
               />
            )
         })}

      </div>
   )
}

const FallbackContainer = () => {
   return (
      <div className='flex flex-col'>
         <span className='text-xl font-semibold'>{CONTAINER_TITLE}</span>
         <span className='text-gray-700 font-NORMAL text-sm mt-2'>{CONTAINER_DESCRIPTION}</span>
      </div>
   )
}