// categories
import categories from '../../pages/integrations/category.json';

// providers
import providers from '../../pages/integrations/providers.json';

function resolveCategoryName(categoryName) {
   return categories.find((i) => i.value === categoryName)
}

function getCategoryNameByType(type) {
   return categories?.find((i) => i.value === type)?.name
}

function getCategoriesTypes() {
   return categories?.map((i) => i.value)
}

/**
 * Filters the service profiles by name based on the provided query.
 *
 * @param {string} query - The search query to filter service profiles by name.
 * @returns {Array} - The filtered list of service profiles whose name includes the query.
 */
function filterByName(query) {

   // Step 1: Filter the 'providers' array based on whether the 'name' includes the 'query'
   const filtered = providers.filter((i) =>
      i?.name?.toLowerCase()?.includes(query?.toLowerCase())
   ) ?? []; // Step 2: If no results, return an empty array

   // Step 3: Return the filtered list
   return filtered;
}

/**
 * Filters the service profiles based on the provided type and query.
 *
 * @param {string} type - The type of service profile to filter by.
 * @param {string} query - The search query to filter service profiles by name.
 * @returns {Array} - The filtered list of service profiles that match the type and optionally the query.
 */
function filterServiceProfiles(type, query) {

   // Step 1: Filter the 'providers' array based on the 'type' and optionally the 'query'
   const filtered = providers.filter((i) => {

      // Step 2: If 'query' is not provided, filter only by 'type'
      if (!query) {
         return i.type === type;
      }

      // Step 3: If 'query' is provided, filter by both 'type' and if 'name' includes the 'query'
      return i.type === type && i?.name?.toLowerCase()?.includes(query?.toLowerCase());
   }) ?? []; // Step 4: If no results, return an empty array

   // Step 5: Return the filtered list
   return filtered;
}

function getAllProviders() {
   const priorityOrder = getCategoriesTypes();

   providers.sort((a, b) => {
      const aPriority = priorityOrder.indexOf(a.type);
      const bPriority = priorityOrder.indexOf(b.type);

      // If both have the same priority (both not in priorityOrder), sort by their original order
      if (aPriority === -1 && bPriority === -1) {
         return 0;
      }

      // Place higher-priority items first
      if (aPriority === -1) return 1; // a is not in priority order
      if (bPriority === -1) return -1; // b is not in priority order

      return aPriority - bPriority;
   });

   return providers;
}


export {
   resolveCategoryName,
   getCategoryNameByType,
   filterServiceProfiles,
   filterByName,
   getAllProviders
}