import React, { HTMLProps } from 'react';
import { cva } from 'class-variance-authority';

import { ItemTypes } from "."
import { cn } from '../../../utils/common';

type Props = {
   title?: string
   items?: Array<ItemTypes>
   sectionsRef: any
   index: number
   nodeId?: string
} & HTMLProps<HTMLDivElement>

const rootVariant = cva(
   cn('grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5'),
   {
      variants: {
      }
   }
);

const itemVariant = cva(
   cn('px-4 py-3 rounded-sm hover:shadow-lg border-[1px] border-solid border-gray-200 flex justify-between items-center'),
   {
      variants: {
      }
   }
);


export default (props: Props) => {

   const { items, className, title, sectionsRef, index, nodeId } = props;

   return (
      <div
         className='flex flex-col gap-4 mt-2'
         ref={(el) => (
            sectionsRef.current[nodeId as string] = el
         )}
         id={nodeId}
      >
         <div>
            <span className='font-semibold'>{title}</span>
         </div>
         <div className={rootVariant({ className })}>
            {items?.map((integration, index) => {
               return (
                  <div
                     key={index}
                     className={itemVariant({})}
                  >
                     <div className='flex flex-row gap-4 items-center'>
                        <img src={integration.image.original} className='w-[2rem] h-[2rem]' alt={integration?.name} />
                        <div className='flex flex-col'>
                           <h3 className="text-sm font-medium break-words">{integration.name}</h3>
                           {/* <span className="text-[.75rem] mt-[2px] text-gray-700">
                              {getCategoryNameByType(integration?.type)}
                           </span> */}
                        </div>
                     </div>
                  </div>
               )
            })}
         </div>
      </div>
   )
}