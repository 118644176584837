import React, { useEffect, useRef, useState } from 'react';

// layout
import Layout from '../../components/common/Layout';

// selection
import Selection from '../../components/integrations/selection';
import Details from '../../components/integrations/details';

// categories
import categories from './category.json';
import StickyScrollContainer from './sticky-container';
import { SectionContainer } from '../../components/common/sectionContainer';

const rootId = 'integration-sections'

export default () => {
   const [selectedCategory, setSelectedCategory] = useState<string>('all-integrations');

   const sectionsRef = useRef({});

   useEffect(() => {

      const observer = new IntersectionObserver((entries) => {
         entries.forEach((entry) => {
            if (entry.isIntersecting) {
               setSelectedCategory(entry.target.id)
            }
         })
      }, { root: null, rootMargin: "-50% 0px -50% 0px", threshold: 0 });

      Object.values(sectionsRef.current)
         .forEach((section: any) => observer.observe(section));

      return () => observer.disconnect();

   }, [sectionsRef.current])

   const onSelectCategory = (newSelectedCategory: string) => {

      const section = sectionsRef.current[newSelectedCategory];

      if (section) {

         // make window to scroll and navigate the element;
         const element = document.getElementById(newSelectedCategory);
         element?.classList.add('scroll-targetCategories');
         element?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start'
         });

         setSelectedCategory(newSelectedCategory);
      }
   }

   return (
      <Layout disablePageTitleSuffix title='Unizo - Integrations' mainCls='overflow-visible overflow-x-visible'>

         <div id={rootId} className='layout banner_spacing_md overflow-visible'>
            <SectionContainer
               title={<span>Amplify your competitive edge with seamless and <span className='primary-text-gradient'>scalable Integrations</span></span>}
               description={`Build seamless workflows orchestrating actions across security and DevOps tools eco-system with Unizo’s out-of-the-box connectors`}
            />
            <StickyScrollContainer
               sticker={
                  <Selection
                     items={categories}
                     onSelect={onSelectCategory as any}
                     selected={selectedCategory as any}
                     className='col-span-2 lg:col-span-1'
                  />
               }
            >
               <Details
                  className='col-span-3 lg:col-span-4'
                  selectedCategory={selectedCategory}
                  sectionsRef={sectionsRef}
               />
            </StickyScrollContainer>
         </div>
      </Layout>
   )
}