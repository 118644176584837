import React, { useRef } from "react";
import { useWindowSize } from "react-use";

const StickyScrollContainer = ({
   sticker,
   children
}: any) => {

   return (
      <div
         className="grid grid-cols-5 gap-4 overflow-visible"
      >
         {sticker}
         {children}
      </div>
   );
};

export default StickyScrollContainer;
